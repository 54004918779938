import { deepReplaceRefsWithDefinitions } from "@merge-api/merge-javascript-shared";
import { MultiLanguageCodeBlock } from "components/docs/shared-components/code-block/MultiOptionCodeBlock";
import type { OpenAPIV3 } from "openapi-types";
import React from "react";
import styled, { CSSProperties } from "styled-components";
import { ColorScheme } from "./shared-components/code-block/themes";
import { getSampleSchema } from "./utils/DocumentationUtils";

type OpenAPIBaseSchemaObject = OpenAPIV3.BaseSchemaObject;
type OpenAPIDocument = OpenAPIV3.Document;

const CodeBlock = styled(MultiLanguageCodeBlock)`
  @media (max-width: 767.96px) {
    margin-top: 16px;
  }
`;

type Props = {
  document: OpenAPIDocument;
  schema?: OpenAPIBaseSchemaObject;
  title?: string;
  style?: CSSProperties;
  colorScheme?: ColorScheme;
};

export const getSampleSchemaFromDocument = (
  schema: OpenAPIBaseSchemaObject,
  document: OpenAPIDocument,
) => {
  const unwrappedSchema = deepReplaceRefsWithDefinitions(schema, document);
  const sampleSchema = getSampleSchema(unwrappedSchema);
  return JSON.stringify(JSON.parse(sampleSchema), null, 2);
};

/**
 * Visualizes a JSON schema with a light syntax highlighting theme
 */
const OpenAPISchemaVisualizer = ({
  document,
  schema,
  title,
  style,
  colorScheme = "light",
}: Props) => {
  let codeContent = "No content";
  if (schema !== undefined) {
    codeContent = getSampleSchemaFromDocument(schema, document);
  }

  if (codeContent == '"bytes"') {
    codeContent = "If successful, this request returns the file content as bytes.";
  }

  return (
    <CodeBlock
      codeBlockName={title}
      colorScheme={colorScheme}
      hideCopyButtonWithHeader
      hasLineNumbers={false}
      isVerticallyPadded={false}
      hideOption={!title}
      style={style}
      languageConfigs={[
        {
          language: "json",
          text: codeContent,
        },
      ]}
      enableTruncation={true}
      codeBlockHeight={310}
    />
  );
};

export default OpenAPISchemaVisualizer;
