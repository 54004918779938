import {
  deepFindFirstValueForKey,
  doesPathItemObjectHaveTag,
} from "@merge-api/merge-javascript-shared";
import CodeBlock from "components/docs/shared-components/code-block/CodeBlock";
import { Link, navigate } from "gatsby";
import React from "react";
import { Col, Row } from "react-bootstrap";
import DocumentationSection from "../../components/docs/DocumentationSection";
import useCategorySchemas from "../../components/docs/hooks/useCategorySchemas";
import HeaderBar from "../../components/docs/layout/HeaderBar";
import CrossGuideLinks from "../../components/docs/navigation/CrossGuideLinks";
import OpenAPISchemaVisualizer from "../../components/docs/OpenAPISchemaVisualizer";
import ParameterRow from "../../components/docs/ParameterRow";
import DocsHelmet from "../../components/docs/shared-components/DocsHelmet";
import { StaticPageContainer } from "../../components/docs/shared-components/PageContainers";
import { RouterLocation } from "../../types/types";

interface Props {
  location: RouterLocation;
}

const LinkedAccountsPage = ({ location }: Props) => {
  // Use ATS category schema, but any category would do since the /linked-accounts route is in all of them.
  const schema = useCategorySchemas().ats;
  const tag = "linked-accounts";
  const linkedAccountsEntry = Object.entries(schema.paths).find(
    ([_, pathItemObject]) =>
      pathItemObject !== undefined && doesPathItemObjectHaveTag(pathItemObject, tag),
  );
  if (linkedAccountsEntry === undefined) {
    // This should not happen; just redirect to / if it does.
    return navigate("/");
  }
  // path is "/linked-accounts"
  const [path, pathItemObject] = linkedAccountsEntry;
  const operationObject = pathItemObject?.get;
  if (operationObject === undefined) {
    // This should not happen; just redirect to / if it does.
    return navigate("/");
  }
  const responseSchemaRef = deepFindFirstValueForKey(operationObject.responses || {}, "schema");
  if (responseSchemaRef === undefined) {
    // This should not happen; just redirect to / if it does.
    return navigate("/");
  }

  const title = "Linked Accounts";
  const description = `The Linked Accounts endpoint lets you ask "For this given customer’s choice of platform, what functionality am I able to take advantage of?"`;
  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} />
      <DocumentationSection title="Overview">
        <>
          <p className="mt-3">
            The Linked Accounts endpoint returns a list of <code>LinkedAccounts</code>. Each{" "}
            <code>LinkedAccount</code> contains, among other things, the following information about
            the models and actions available for that account:
          </p>
          <ul>
            <li>
              <code>integration</code>: Includes name, categories, image, square image, and primary
              color. You can use this information to build an adaptive UI that accurately reflects
              the integration your user has chosen to link.
            </li>
            <li>
              <code>passthrough_available</code>: defines whether you are able to use the{" "}
              <Link to="/supplemental-data/overview">Passthrough endpoint</Link> to make custom
              requests of any kind to the given API. This value is almost always true.
            </li>
            <li>
              <code>available_model_operations</code>: For the provided integration, we return each
              supported data model, the supported operations (<code>FETCH</code>/<code>CREATE</code>
              /<code>UPDATE</code>/<code>DELETE</code>), the supported fields, and the required
              fields to create an instance of that model.
            </li>
          </ul>
        </>
      </DocumentationSection>
      <hr className="mt-6 mb-9" />
      <DocumentationSection title="Request">
        <p>
          The request will return all linked accounts regardless of which category is passed into
          the URL.
        </p>
        <p>
          You will also want to include an Authorization header that includes your Merge API key -
          see the Authentication section for more details.
        </p>
        <CodeBlock
          language="bash"
          text="GET https://api.merge.dev/api/ats/v1/linked-accounts"
          hasLineNumbers={false}
          hideCopyButtonWithHeader
        />
      </DocumentationSection>
      <hr className="mt-6 mb-9" />
      <DocumentationSection title="">
        <OpenAPISchemaVisualizer
          document={schema}
          schema={responseSchemaRef}
          title="Example Linked Accounts Response"
        />
      </DocumentationSection>
      <CrossGuideLinks location={location} style={{ marginTop: "40px" }} />
    </StaticPageContainer>
  );
};

export default LinkedAccountsPage;
